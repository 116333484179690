<template>
  <div class="content">
    <el-card shadow="never" class="new-card">
      <div slot="header" class="card-header">
        <div class="card-header-left">
          <div class="top-title">产品组关联</div>
        </div>
        <div class="card-header-left">
          <el-button
            type="primary"
            size="small"
            :loading="downloadLoading"
            @click="downloadData"
            >导出</el-button
          >
          <el-button type="primary" size="small" @click="downloadTemplate"
            >下载模板</el-button
          >
          <el-upload
            class="upload-demo"
            action
            :on-change="(file, fileList) => handleChange(file, fileList)"
            :auto-upload="false"
            :show-file-list="false"
            accept=".xls,.xlsx"
          >
            <el-button size="small" style="margin: 0 10px" type="primary"
              >导入</el-button
            >
          </el-upload>
        </div>
      </div>
      <div :style="{ overflowY: 'auto', height: tableHeight }">
        <el-table
          size="small"
          :data="tableData"
          border
          height="calc(100% - 35px)"
        >
          <el-table-column
            label="产品组"
            prop="productGroupName"
            align="center"
          />
          <el-table-column label="关联产品数量" prop="count" align="center" />
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="handleEdit(scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import store from "@/store";
export default {
  data() {
    return {
      tableData: [], // 表格数据
      tableHeight: "850px",
      downloadLoading: false,
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    // 获取高度
    this.tableHeight = document.body.clientHeight - 220 + "px";

    // 全选的数据
    this.oneDimensionalList = [];
  },
  methods: {
    handleChange(file, fileList) {
      console.log("file", file);
      let formData = new FormData();
      formData.append("file", file.raw);
      console.log(formData);
      this.upload_file(formData);
    },
    upload_file(val) {
      this.$store.dispatch("app/setLoading", true);
      this.axios
        .post("PRODUCTRELATIONIMPORT", val)
        .then((result) => {
          console.log("查询返回", result);
          if (result.code === 0) {
            this.$message({
              message: "导入成功",
              type: "success",
            });
            this.getList();
          } else {
            const str = result.msg.replace(/(\n|\r|\r\n|↵)/g, "<br/>");
            this.$message({
              dangerouslyUseHTMLString: true,
              type: "error",
              message: str,
            });
          }
          this.$store.dispatch("app/setLoading", false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
    downloadData() {
      this.downloadLoading = true;
      this.axios
        .get("PRODUCTEXPORTALL", {})
        .then((result) => {
          console.log("result instanceof Blob", result);
          if (result instanceof Blob) {
            const blob = new Blob([result]);
            console.log(blob);
            const fileName = "产品组全量数据" + ".xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          } else {
            this.$message.error("导出失败！");
          }
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },
    downloadTemplate() {
      this.axios.get("PRODUCTEXPORT", {}).then((result) => {
        console.log("result instanceof Blob", result);
        if (result instanceof Blob) {
          const blob = new Blob([result]);
          console.log(blob);
          const fileName = "产品组关联模板" + ".xlsx";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        } else {
          this.$message.error("导出失败！");
        }
      });
    },
    // 打开编辑
    handleEdit(items) {
      this.$router.push(
        `/area_productRelation_edit/${items.productGroupCode}?name=${items.productGroupName}`
      );
    },
    // 获取列表
    getList() {
      this.axios.get("PRODUCTRELATIONLIST", {}).then((result) => {
        console.log("result", result);
        this.tableData = result.data;
      });
    },
  },
};
</script>

<style scoped>
.modular-box {
  display: flex;
  flex-direction: column;
}
.el-cascader__tags .el-tag {
  color: #909399;
}
.new-card .el-card__header {
  padding: 13px 20px;
}
.new-card .el-card__body {
  padding: 0 20px;
  /* background: #F0F2F5; */
}
.el-dialog__headerbtn .el-dialog__close {
  color: #1cd097;
}
.el-table::before {
  z-index: inherit;
}
.new-tag1 {
  border-radius: 4px;
  background: #2672ff10;
  color: #2672ff;
  border: 1px solid #2672ff50;
  padding: 4px 10px;
}
.new-tag2 {
  border-radius: 4px;
  background: #ffa50f10;
  color: #ffa50f;
  border: 1px solid #ffa50f50;
  padding: 4px 10px;
}
.new-tag3 {
  border-radius: 4px;
  background: #ff532210;
  color: #ff5322;
  border: 1px solid #ff532250;
  padding: 4px 10px;
}
.new-tag4 {
  border-radius: 4px;
  background: #9d4de610;
  color: #9d4de6;
  border: 1px solid #9d4de650;
  padding: 4px 10px;
}
.new-tag5 {
  border-radius: 4px;
  background: #1cd09710;
  color: #1cd097;
  border: 1px solid #1cd09750;
  padding: 4px 10px;
  width: 90px;
  margin: 0 auto;
}
</style>

<style lang="scss" scoped>
.content {
  overflow-y: auto;
  height: 90vh;
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .card-header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .top-title {
        font-size: 16px;
        color: #262626;
        font-weight: bold;
      }
      .question {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
      .question:hover {
        cursor: pointer;
      }
    }
    .card-header-right {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }
  .auth-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .auth-show-input {
    width: 300px;
  }
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>
